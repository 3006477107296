import React, {useState} from 'react'
import Nav from '../Global/Nav'
import Footer from '../Global/Footer'
import Hero from './Hero'
import Features from './Features'
import zigzag from "../../Assets/Vectorzigzag (1).png"
import AboutSection from './AboutSection'
import Steps from './Steps'
import HeroSection from '../Global/HeroSection'
import axios from 'axios'
import { Link } from 'react-router-dom'

const Home = () => {
  const [emailb, setEmailb] = useState('')
  
  const joinWaitBottom = async (e) => {
    e.preventDefault()
    try {
        let res = await axios.post('https://breakdownassistant.com//register/user/waitlist', {
            email: emailb,
        })
        alert(res.data.msg);
        setEmailb('')
    } catch (error) {
        if (error.response) {
          alert(error.response.data.msg)
        }
    }
  }
  return (
    <section className='bg-[#F8F8FF] w-full pt-7 h-full'>
      <Nav />
    
      <Hero />
      <Features />
      <AboutSection />

      <Steps />
      <section className='bg-[#6869FF] mb-24 rounded-2xl mx-10 sm:mx-5 relative flex py-16 justify-center items-center flex-col'>
        <img src={zigzag} className='absolute sm:h-full sm:object-cover top-0 right-0 left-0 bottom-0' alt="" />
        <h2 className='font-medium text-4xl sm:text-2xl text-white'>Don’t miss out!</h2>
        <p className='text-[#ffffffcc] text-base sm:text-sm sm:text-center sm:px-5 font-normal mt-2 mb-8'>Sign up to join the waiting list and be the first to know when we launch</p>
        <div className='bg-[#F8F8FF] z-30 p-2 sm:w-[90%] sm:flex sm:flex-col  w-fit sm:mx-auto  rounded-xl' style={{ border: '1px solid #0000000d' }}>
        { /*<input value={emailb} onChange={(e) => setEmailb(e.target.value)} type="email" className='h-[49px] sm:w-full pl-4 pr-28 sm:pr-0 focus:outline-none bg-transparent' placeholder='Your email' />
          <button onClick={joinWaitBottom} className='bg-[#6869ff] text-white sm:w-fit sm:mx-auto my-auto text-sm font-normal h-[49px] px-5 rounded-xl'>Join waitlist</button>*/ }
          <Link target='_blank' to='https://057785a3.sibforms.com/serve/MUIFABBbVQ9TDg0H_fC9Hl6i_VhFrSHRAXEy_Z_9k91RQQHwD1fg_69oiRDU6wBJ78N3YYQ94IuG1haTxmGw1rwchbsM7ag1IahsZoMxbDofdPWTBr9o2J1SoM_JHhbM18WY2msUxdEs3dBotnmap8Bfk_JDqUYBvFRqqSp3bsiJrwpHXwvXW-VjILkcSB40LAGaV4sb7EOgbO5T'>
            <button className='bg-[#6869ff] text-white sm:w-fit sm:mx-auto my-auto text-sm font-normal h-[49px] px-5 rounded-xl'>Join waitlist</button>
          </Link>
        </div>
      </section>
      <Footer />
    </section>
  )
}

export default Home